/**
 * @Author: xiaoyaqu
 * @Date: 2019-05-31 21:31:08.486
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:47
 */

import Lottery from 'investaja/common/resource/lottery';

export const M_TYPES = {
    SET_LOTTERY_INFO: 'user/set_lottery_info',
    SET_REMAIN_CHANCE: 'user/set_remain_chance',
    CHANGE_STATUS: 'user/change_status'
};

export const A_TYPES = {
    SAVE_LOTTERY_INFO: 'saveLotteryInfo'
};

export const G_TYPES = {
    REMAIN_CHANCE: 'remainChance',
    RESULTS: 'results',
    PROMOTION_INFO: 'promotionInfo'
};

export default {
    state: () => ({
        fetched: false,
        lotteryInfo: {
            lotteryChance: {},
            promotionInfo: {},
            results: []
        }
    }),

    getters: {
        [G_TYPES.REMAIN_CHANCE]: state => state.lotteryInfo.lotteryChance.remainChance,
        [G_TYPES.RESULTS]: state => state.lotteryInfo.results,
        [G_TYPES.PROMOTION_INFO]: state => state.lotteryInfo.promotionInfo
    },

    mutations: {
        [M_TYPES.SET_LOTTERY_INFO]: (state, lotteryInfo) => {
            state.lotteryInfo = lotteryInfo;
            state.fetched = true;
        },
        [M_TYPES.SET_REMAIN_CHANCE]: (state, remainChance) => {
            state.lotteryInfo.lotteryChance.remainChance = remainChance;
        },
        [M_TYPES.CHANGE_STATUS]: (state, fetched) => {
            state.fetched = fetched;
        }
    },

    actions: {
        [A_TYPES.SAVE_LOTTERY_INFO]: ({commit}, config) => (
            Lottery.getLotteryInfo(config)
                .then(({data: {body: lotteryInfo}}) => commit(M_TYPES.SET_LOTTERY_INFO, lotteryInfo))
                .catch(x => x))
    }
};
