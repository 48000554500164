/*
 * @Author: chengyuzhang
 * @Date: 2021-03-11 15:30:23
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2021-03-11 15:30:52
 */

export default {
    namespaced: true,

    state: () => ({
        primaryColor: '#143BE6',
        brand: 'investaja'
    })
};
