<!--
 * @Author: zhaoyang
 * @Date: 2023-08-24 19:18:13
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-08-26 22:06:46
-->

<template>
    <div id="app">
        <router-view>Hello SSR!</router-view>
        <ec-webview-loading-circle
            v-show="!!reqCount"
            loading-color="#143be6"
        />
    </div>
</template>

<script>
import {Toast} from 'vant';

import {genWebDeviceToken} from 'ssr-common/util/browser-util';
import ua from 'ssr-common/util/webview/ua-parsed';
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';
import yqgCookie from 'ssr-common/util/yqg-cookie';
import Vue from 'ssr-common/vue';
import interceptor from 'ssr-common/vue/mixin/interceptor';
import platformType from 'ssr-common/vue/mixin/platform-type';
import Vconsole from 'ssr-common/vue/mixin/vconsole';
import http from 'ssr-common/vue/vue-http';

import EcWebviewLoadingCircle from 'easycash/common/component/ec-webview-loading-circle';
import filter from 'easycash/common/filter';
import uploadEvent from 'easycash/common/mixin/upload-event';

import component from 'investaja/common/component';
import directive from 'investaja/common/directive';
import plugin from 'investaja/common/plugin';

const {common} = http.defaults.headers;
common['YQG-PLATFORM-SDK-TYPE'] = 'IDN_FIN';
common['YQG-PLATFORM-DEVICE-TOKEN'] = genWebDeviceToken();

Vue.use(component);
Vue.use(plugin);
Vue.use(directive);
Vue.use(filter);

export default {
    name: 'Investaja',

    components: {
        // eslint-disable-next-line vue/no-unused-components
        Toast,
        EcWebviewLoadingCircle
    },

    mixins: [Vconsole(Vue), interceptor, uploadEvent, platformType],

    created() {
        common['YQG-PLATFORM-LANGUAGE'] = this.$i18n.locale;
        Vue.prototype.$app = this;
    },

    mounted() {
        // 解决ios登陆后丢失cookie问题
        this.setCookie();
    },

    methods: {
        setCookie() {
            if (ua.isIOS) {
                const matchArr = this.cookie?.match(/userToken=(.*?)(;|$)/);
                const userToken = matchArr && matchArr[1];
                if (userToken) {
                    yqgCookie.setUserToken(userToken);
                }
            }
        },

        $routerPush(route) {
            let href = '';
            if (typeof route === 'object' && route.link) {
                href = route.link;
            } else {
                href = this.$router.resolve(typeof route === 'string' ? {name: route} : route).href;
            }

            WebviewInterface.openWebview(href);
        }
    }
};
</script>

<style lang="scss">
@import "~investaja/common/style/variables.scss";

body {
    margin: 0;
    padding: 0;
    font-size: 12px;

    $TOAST_ALPHA: 0.9;

    @media screen and (max-device-width: 480px) {
        & {
            text-size-adjust: none;
        }
    }

    .b-toast {
        position: fixed;
        left: 50%;
        top: 50%;
        padding: 10px 20px;
        max-width: 568px;
        transform: translate(-50%, -50%);
        z-index: $z-index-toast;
        line-height: 1.2em;
        text-align: center;
        border-radius: 4px;
        background-color: rgba($info, $TOAST_ALPHA);
        box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 20%), 0 1px 1px 0 rgba(0, 0, 0, 14%), 0 1px 3px 0 rgba(0, 0, 0, 12%);
        color: white;
        animation: 0.36s cubic-bezier(0.78, 0.14, 0.15, 0.86) toast-fade-in;
    }

    .SUCCESS {
        background-color: rgba($success, $TOAST_ALPHA);
    }

    .ERROR {
        background-color: rgba($error, $TOAST_ALPHA);
    }

    .b-toast-fade-enter-active,
    .b-toast-fade-leave-active {
        margin-top: 0;
        transition: 0.36s ease-out;
    }

    .b-toast-fade-enter,
    .b-toast-fade-leave-to {
        margin-top: 10px;
        opacity: 0%;
    }

    @media only screen and (min-width: 500px) {
        .b-toast {
            position: fixed;
            top: 4vh;
            right: 3vw;
            left: auto;
            bottom: auto;
            transform: none;
            min-width: 10vw;
            max-width: 20vw;
            text-align: center;
        }
    }

    .scroll-bar {
        height: 100px;
        width: 3px;
        top: 0;
        border-radius: 3px;
        position: absolute;
        background: #a3acbc;
        transition: opacity 0.3s ease-in-out;
    }
}
</style>
