/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:42
 */

import {Toast} from 'vant';

import AntdIcon from './antd-icon';

export default {

    install(Vue) {
        Vue.use(AntdIcon);
        Vue.use(Toast);
    }

};
