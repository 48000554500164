/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:41
 */
/* global __API_HOST__ RUN_PORT */

export const rootTitle = 'Easycash Lender - enjoy easier life';

export const API_HOST = __API_HOST__;

export const BRAND = 'investaja';

export const noStageTitle = ['feat-overseas'];

export const getEurekaHost = () =>
    (typeof window !== 'undefined' && window.location.origin)
    || `http://127.0.0.1:${RUN_PORT}`;

