/*
 * @Author: qxy
 * @Date: 2019-11-26 15:41:53
 * @Last Modified by: qxy
 * @Last Modified time: 2019-11-26 18:32:13
 */

export default {
    state: {
        content: []
    },

    mutations: {
        saveVersion: (state, content) => {
            state.content = content;
        }
    }
};

