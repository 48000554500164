/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-26 14:15:34
 */

import BusinessEvents from 'investaja/common/constant/business-events';

import RoutesBiz from './routes-biz';

export default [
    {
        path: '',
        name: 'index',
        component: () => import('investaja/app/index')
    },
    {
        path: '/agreement/service',
        name: 'service',
        component: () => import('investaja/app/agreement/service')
    },
    {
        path: '/webview',
        component: () => import('investaja/app/webview'),
        children: [
            {
                path: '/webview/about-us',
                name: 'aboutUs',
                component: () => import('investaja/app/webview/about-us')
            },
            {
                path: '/webview/agreement/:key',
                name: 'agreement',
                component: () => import('easycash/app/webview/agreement'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.AgreementPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'protocol',
                name: 'protocol',
                component: () => import('investaja/app/webview/protocol'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ProtocolPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/static-agreement/:key',
                name: 'staticAgreement',
                component: () => import('easycash/app/webview/static-agreement'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.StaticAgreementPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/static-text/:key',
                name: 'staticText',
                component: () => import('easycash/app/webview/static-text')
            },
            {
                path: '/webview/pdf-view',
                name: 'pdfView',
                component: () => import('easycash/app/webview/pdf-view')
            },
            {
                path: '/webview/product-detail/:agreementKey',
                name: 'productDetail',
                component: () => import('investaja/app/webview/product-detail')
            },
            {
                path: '/webview/new-product-detail/:agreementKey',
                name: 'newProductDetail',
                component: () => import('investaja/app/webview/new-product-detail'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/version-code',
                name: 'versionCode',
                component: () => import('investaja/app/webview/version-code')
            },
            {
                path: '/webview/version-code/record',
                name: 'record',
                component: () => import('investaja/app/webview/version-code/record')
            },
            // {
            //     path: '/webview/activity/login',
            //     name: 'login',
            //     component: () => import('investaja/app/webview/activity/login')
            // },
            // {
            //     path: '/webview/activity/lottery',
            //     name: 'lottery',
            //     component: () => import('investaja/app/webview/activity/lottery')
            // },
            {
                path: '/webview/activity/invite',
                name: 'invite',
                component: () => import('investaja/app/webview/activity/invite')
            },
            {
                path: '/webview/activity/register',
                name: 'register',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ActivityRegisterPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('investaja/app/webview/activity/register')
            },
            {
                path: '/webview/activity/download',
                name: 'download',
                component: () => import('investaja/app/webview/activity/download')
            },
            {
                path: '/webview/activity/download-alpha',
                name: 'download-alpha',
                component: () => import('investaja/app/webview/activity/download')
            },
            {
                path: '/webview/activity/download-beta',
                name: 'download-beta',
                component: () => import('investaja/app/webview/activity/download')
            },
            {
                path: '/webview/activity/hike',
                name: 'hike',
                component: () => import('investaja/app/webview/activity/hike')
            },
            {
                path: '/webview/activity/rank/:activityId?',
                name: 'rank',
                component: () => import('investaja/app/webview/activity/rank')
            },
            {
                path: '/webview/activity/rank/detail/:activityId?',
                name: 'rankDetail',
                component: () => import('investaja/app/webview/activity/rank/detail')
            },
            {
                path: '/webview/activity/sudoku-lottery/:activityId?',
                name: 'sudokuLottery',
                component: () => import('investaja/app/webview/activity/sudoku-lottery')
            },
            {
                path: '/webview/activity/sudoku-lottery/detail/:activityId?',
                name: 'sudokuLotteryDetail',
                component: () => import('investaja/app/webview/activity/sudoku-lottery/detail')
            },
            {
                path: '/webview/activity/four-year-report',
                name: 'FourYearReport',
                component: () => import('investaja/app/webview/activity/four-year-report'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.FourYearPageEnter(1)
                    }
                }
            },
            {
                path: '/webview/activity/four-year-report/register',
                name: 'FourYearReportRegister',
                component: () => import('investaja/app/webview/activity/four-year-report/register'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.FourYearRegisterPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/activity/get-coupon',
                name: 'GetCoupon',
                component: () => import('investaja/app/webview/activity/get-coupon')
            },
            {
                path: '/webview/activity/divide-cash/:activityId',
                name: 'DivideCash',
                component: () => import('investaja/app/webview/activity/divide-cash')
            },
            {
                path: '/webview/activity/integral',
                name: 'FiveYearReportTwo',
                component: () => import('investaja/app/webview/activity/integral'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.FiveYearGetCouponPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/activity/integral-new',
                name: 'IntegralNew',
                component: () => import('investaja/app/webview/activity/integral/index-new'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/activity/point-record',
                name: 'PointRecord',
                component: () => import('investaja/app/webview/activity/integral/point-record'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/activity/receive-record',
                name: 'ReceiveRecord',
                component: () => import('investaja/app/webview/activity/integral/receive-record'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: '/webview/activity/report',
                name: 'activityReport',
                component: () => import('investaja/app/webview/activity/report'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.ActivityReportPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'questionnaire',
                name: 'questionnaire',
                component: () => import('investaja/app/webview/questionnaire')
            },
            {
                path: 'product-detail-debt/:agreementKey',
                name: 'ProductDetailDebt',
                component: () => import('investaja/app/webview/debt/product-detail'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'borrower-detail',
                name: 'BorrowerDetail',
                component: () => import('investaja/app/webview/debt/borrower-detail'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'auto-borrower-detail',
                name: 'AutoBorrowerDetail',
                component: () => import('investaja/app/webview/debt/auto-borrower-detail'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'apply-detail',
                name: 'ApplyDetail',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import('investaja/app/webview/debt/apply-detail')
            },
            {
                path: 'match-detail',
                name: 'MatchDetail',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import('investaja/app/webview/debt/match-detail')
            },
            {
                path: 'debt-faq',
                name: 'DebtFaq',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import('investaja/app/webview/debt/faq')
            },
            {
                path: 'auto-faq',
                name: 'AutoFaq',
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                },
                component: () => import('investaja/app/webview/debt/auto-faq')
            },
            {
                path: 'message',
                name: 'Message',
                component: () => import('investaja/app/webview/message-center/message')
            },
            {
                path: 'message/:id',
                name: 'MessageDetail',
                component: () => import('investaja/app/webview/message-center/message-detail')
            },
            {
                path: 'coupon-instructions',
                name: 'CouponInstructions',
                component: () => import('easycash/app/webview/coupon-instructions'),
                meta: {
                    titleKey: 'webview.title.couponInstructions'
                }
            },
            {
                path: 'invitation-detail/:type?',
                name: 'InvitationDetail',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.InvitationDetailPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('investaja/app/webview/invitation/detail')
            },
            {
                path: 'invitation/:type?',
                name: 'Invitation',
                meta: {
                    business: {
                        enterEvent: BusinessEvents.InvitationPageEnter,
                        uploadQuery: true
                    }
                },
                component: () => import('investaja/app/webview/invitation')
            },
            {
                path: 'vip-welfare',
                name: 'VipWelfare',
                component: () => import('investaja/app/webview/vip-welfare')
            },
            {
                path: 'withholdingTaxSlip',
                name: 'PayTaxes',
                component: () => import('investaja/app/webview/pay-taxes')
            },
            {
                path: 'vip',
                name: 'Vip',
                component: () => import('investaja/app/webview/vip/index')
            },
            {
                path: 'vip-rule',
                name: 'VipRule',
                component: () => import('investaja/app/webview/vip/rule')
            },
            {
                path: 'vip-address/:level?',
                name: 'VipAddress',
                component: () => import('investaja/app/webview/vip/address')
            },
            {
                path: 'social-media',
                name: 'SocialMedia',
                component: () => import('investaja/app/webview/social-media'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'benefit',
                name: 'BenefitDetail',
                component: () => import('investaja/app/webview/vip/benefit-detail')
            },
            {
                path: 'credit-insurance',
                name: 'CreditInsurance',
                component: () => import('investaja/app/webview/credit-insurance')
            },
            {
                path: 'agreement-pdf',
                name: 'AgreementPdf',
                component: () => import('easycash/app/webview/agreement-pdf')
            },
            {
                path: 'investaja-agreement-pdf',
                name: 'AgreementPdfInvestaja',
                component: () => import('investaja/app/webview/agreement-pdf/agreement-apdf.vue')
            },
            {
                path: 'agreement-pdf-new',
                name: 'AgreementPdfNew',
                component: () => import('investaja/app/webview/agreement-pdf/index.vue')
            },
            {
                path: 'agreement-pdf-list',
                name: 'AgreementPdfList',
                component: () => import('investaja/app/webview/agreement-pdf/agreement-list.vue'),
                meta: {
                    business: {
                        buriedPoint: true,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'help-center',
                name: 'CustomerService',
                component: () => import('easycash/app/webview/customer-service')
            },
            {
                path: 'upload-info',
                name: 'UploadInfo',
                component: () => import('investaja/app/webview/upload-info')
            },
            {
                path: 'customer-service/faq',
                name: 'CustomerServiceFaq',
                component: () => import('easycash/app/webview/customer-service/faq')
            },
            {
                path: 'customer-service/complaint',
                name: 'CustomerServiceComplaint',
                component: () => import('easycash/app/webview/customer-service/complaint')
            },
            {
                path: 'privyid-faq',
                name: 'PrivyIDFaq',
                component: () => import('easycash/app/webview/privyid-faq')
            },
            {
                path: 'upload-npwp',
                name: 'UploadNpwp',
                component: () => import('investaja/app/webview/upload-npwp')
            },
            {
                path: 'upload-npwp/success',
                name: 'UploadNpwpSuccess',
                component: () => import('investaja/app/webview/upload-npwp/success')
            },
            {
                path: 'upload-npwp/faq',
                name: 'UploadNpwpFaq',
                component: () => import('investaja/app/webview/upload-npwp/faq')
            },
            {
                path: 'logout',
                name: 'Logout',
                component: () => import('easycash/app/webview/logout-page')
            },
            {
                path: 'rdl',
                name: 'Rdl',
                component: () => import('investaja/app/webview/rdl'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.RdlAccountBalancePageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'rdl/withdrawal',
                name: 'Withdrawal',
                component: () => import('investaja/app/webview/rdl/withdrawal'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.RdlAccountWithdrawalPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'rdl/topUp',
                name: 'TopUp',
                component: () => import('investaja/app/webview/rdl/topUp'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.RdlAccountTopUpPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'rdl/lendTopUp',
                name: 'LendTopUp',
                component: () => import('investaja/app/webview/rdl/lendTopUp'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.RdlAccountLendTopUpPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'rdl/bindCard',
                name: 'BindCard',
                component: () => import('investaja/app/webview/rdl/bindCard'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.RdlAccountBindCardPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'associate-npwp',
                name: 'AssociateNpwp',
                component: () => import('investaja/app/webview/associate-npwp'),
                meta: {
                    business: {
                        enterEvent: BusinessEvents.AssociateNpwpPageEnter,
                        uploadQuery: true
                    }
                }
            },
            {
                path: 'pengumuman',
                name: 'Pengumuman',
                component: () => import('investaja/app/webview/pengumuman/Pengumuman')
            },
            {
                path: 'pdf-preview',
                name: 'PdfPreview',
                component: () => import('investaja/app/webview/pdf-preview')
            },
            {
                path: 'mandiri-rdl',
                name: 'MandiriRdl',
                component: () => import('investaja/app/webview/mandiri-rdl')
            },
            {
                path: 'mandiri-rdl-applied',
                name: 'MandiriRdlApplied',
                component: () => import('investaja/app/webview/mandiri-rdl-applied')
            },
            ...RoutesBiz
        ]
    },
    {
        path: '/demo/index/:id',
        name: 'demo',
        component: () => import('investaja/app/demo'),
        props: true
    },
    {
        path: '*',
        component: () => import('investaja/app/not-found')
    }
];
