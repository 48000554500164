<!-- @Author: ruiwang -->
<!-- @Author: lijiahang -->
<!-- @Date: 2024-03-06 16:44:16.637 -->
<!-- @Last Modified by: lijiahang -->
<!-- @Last Modified time: 2024-03-06 16:00:47 -->
<template>
    <div class="ec-layout">
        <nav-bar
            class="nav-bar"
            v-bind="navBar"
            :custom-back="customBack"
            @mounted="handleNavBarMounted"
        >
            <template
                v-if="$slots['navBar.right']"
                #right
            >
                <slot name="navBar.right" />
            </template>
            <template
                v-if="$slots['navBar.title']"
                #title
            >
                <slot name="navBar.title" />
            </template>
        </nav-bar>
        <div
            v-if="navBarHeight !== null"
            class="ec-layout-wrapper"
            :style="wrapperStyleVal"
        >
            <div
                ref="content"
                class="ec-layout-wrapper-content"
                :class="{'content-fixed': footerFixed}"
                :style="contentStyle"
                v-on="$listeners"
            >
                <slot />
            </div>
            <div
                v-if="$slots.footer"
                class="ec-layout-wrapper-footer"
                :class="{
                    'footer-fixed': footerFixed
                }"
                :style="footerStyle"
            >
                <slot name="footer" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'EcLayout',

    props: {
        navBar: {
            type: Object,
            default: () => ({})
        },

        wrapperStyle: {
            type: Object,
            default: () => ({})
        },

        contentStyle: {
            type: Object,
            default: () => ({})
        },

        footerStyle: {
            type: Object,
            default: () => ({})
        },

        footerFixed: {
            type: Boolean,
            default: false
        },

        customBack: {
            type: [Function, undefined],
            default: undefined
        }
    },

    data() {
        return {
            navBarHeight: null
        };
    },

    computed: {
        wrapperStyleVal() {
            const {navBarHeight, wrapperStyle, navBar: {position}} = this;
            const isFixed = position === 'fixed';

            return {
                height: isFixed ? '100vh' : `calc(100vh - ${navBarHeight}px)`,
                ...wrapperStyle
            };
        }
    },

    methods: {
        scrollTo(offset) {
            const el = this.$refs.content;
            if (el) {
                el.scrollTop = offset;
            }
        },

        handleNavBarMounted(height) {
            this.navBarHeight = height;
            this.$emit('navMounted', height || 0);
        }
    }
};
</script>

<style lang="scss" scoped>
div.ec-layout {
    padding: 0;
    height: 100vh;
    box-sizing: border-box;

    &-wrapper {
        font-family: "Helvetica", sans-serif;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &-content {
            box-sizing: border-box;
            padding: 0.25rem 0.15rem 0;
            flex: 1;
            overflow: auto;
        }

        .content-fixed {
            padding-bottom: 1.1rem;
        }

        &-footer {
            width: 100%;
            box-sizing: border-box;
            padding: 0.15rem;
        }

        .footer-fixed {
            position: fixed;
            bottom: 0;
            left: 0;
            background: linear-gradient(to bottom, rgb(245, 251, 251, 50%), rgba(245, 251, 251) 0.25rem);
        }
    }
}
</style>

