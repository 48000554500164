var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("router-view", [_vm._v("Hello SSR!")]),
      _vm._v(" "),
      _c("ec-webview-loading-circle", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !!_vm.reqCount,
            expression: "!!reqCount",
          },
        ],
        attrs: { "loading-color": "#143be6" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }