/**
 * @Author: lijiahang
 * @Date: 2024-03-06 16:24:19
 * @Last Modified by: lijiahang
 * @Last Modified time: 2024-03-06 16:24:19
 */
import Resource from '@yqg/resource';

const api = {
    uploadEvents: {
        url: '/event/uploadEvents/v2',
        method: 'post'
    }
};

export default Resource.create(api);
