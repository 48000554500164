/*
 * @Author: qxy
 * @Date: 2020-03-27 14:12:13
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2021-04-20 14:00:19
 */

import {BRAND} from 'investaja/common/constant/config';

import generateEvent from './event';
import scrollBar from './scroll-bar';

const event = generateEvent(BRAND);

export default {
    install(Vue) {
        Vue.directive('scroll-bar', scrollBar);
        Vue.directive('yqg-event', event);
    }
};
