/*
 * @Author: chengyuzhang
 * @Date: 2021-01-25 18:40:48
 * @Last Modified by: bowang
 * @Last Modified time: 2023-04-21 11:49:45
 */
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import EventUtil from '../util/event';
export default (BRAND) => ({
    bind(el, { value }, vnode) {
        if (!value) {
            return;
        }
        if (value.type === 'input') {
            const { tag, businessEvent, uploadValue } = value, otherParams = __rest(value, ["tag", "businessEvent", "uploadValue"]);
            const focusParams = Object.assign({ tag: `${tag}InputFocus`, businessEvent: `${businessEvent}InputFocus`, elementType: 'Button', eventType: 'C' }, otherParams);
            const blurParams = Object.assign({ tag: `${tag}InputBlur`, businessEvent: `${businessEvent}InputBlur`, elementType: 'Button', eventType: 'C' }, otherParams);
            el.addEventListener('focus', () => {
                EventUtil.uploadEvent(focusParams || {}, vnode.context.$route, BRAND);
            });
            el.addEventListener('blur', (event) => {
                var _a;
                EventUtil.uploadEvent((uploadValue ? Object.assign(Object.assign(Object.assign({}, blurParams), otherParams), { elementContent: Object.assign(Object.assign({}, otherParams.elementContent), { [typeof uploadValue === 'string' ? uploadValue : 'inputVal']: (_a = event === null || event === void 0 ? void 0 : event.target) === null || _a === void 0 ? void 0 : _a.value }) }) : blurParams) || {}, vnode.context.$route, BRAND);
            });
        }
        else {
            el.addEventListener('click', () => {
                EventUtil.uploadEvent(value || {}, vnode.context.$route, BRAND);
            });
        }
    }
});
