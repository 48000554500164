/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-10-11 15:50:48
 */

import Vuex, {Store} from 'vuex';

import Vue from 'ssr-common/vue';

import baseData from './module/base-data';
import contact from './module/contact';
import user from './module/user';
import version from './module/version';

Vue.use(Vuex);

export const createStore = () => new Store({
    modules: {
        user,
        version,
        baseData,
        contact
    }
});
