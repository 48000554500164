var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ec-layout" },
    [
      _c(
        "nav-bar",
        _vm._b(
          {
            staticClass: "nav-bar",
            attrs: { "custom-back": _vm.customBack },
            on: { mounted: _vm.handleNavBarMounted },
            scopedSlots: _vm._u(
              [
                _vm.$slots["navBar.right"]
                  ? {
                      key: "right",
                      fn: function () {
                        return [_vm._t("navBar.right")]
                      },
                      proxy: true,
                    }
                  : null,
                _vm.$slots["navBar.title"]
                  ? {
                      key: "title",
                      fn: function () {
                        return [_vm._t("navBar.title")]
                      },
                      proxy: true,
                    }
                  : null,
              ],
              null,
              true
            ),
          },
          "nav-bar",
          _vm.navBar,
          false
        )
      ),
      _vm._v(" "),
      _vm.navBarHeight !== null
        ? _c(
            "div",
            { staticClass: "ec-layout-wrapper", style: _vm.wrapperStyleVal },
            [
              _c(
                "div",
                _vm._g(
                  {
                    ref: "content",
                    staticClass: "ec-layout-wrapper-content",
                    class: { "content-fixed": _vm.footerFixed },
                    style: _vm.contentStyle,
                  },
                  _vm.$listeners
                ),
                [_vm._t("default")],
                2
              ),
              _vm._v(" "),
              _vm.$slots.footer
                ? _c(
                    "div",
                    {
                      staticClass: "ec-layout-wrapper-footer",
                      class: {
                        "footer-fixed": _vm.footerFixed,
                      },
                      style: _vm.footerStyle,
                    },
                    [_vm._t("footer")],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }