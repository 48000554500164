/*
 * @Author: chengyuzhang
 * @Date: 2021-01-26 13:26:57
 * @Last Modified by: wuhuiwang
 * @Last Modified time: 2023-12-08 14:13:22
 */

export default {
    InvitationPageEnter: {
        tag: 'InvitationPageEnter',
        businessEvent: 'Investaja_InvitationPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    InvitationShareButton: {
        tag: 'InvitationShareButton',
        businessEvent: 'Investaja_Invitation_ShareButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationFacebookButton: {
        tag: 'InvitationFacebookButton',
        businessEvent: 'Investaja_Invitation_FacebookButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationSmsButton: {
        tag: 'InvitationSmsButton',
        businessEvent: 'Investaja_Invitation_SmsButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationWhatsAppButton: {
        tag: 'InvitationWhatsAppButton',
        businessEvent: 'Investaja_Invitation_WhatsAppButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationMailButton: {
        tag: 'InvitationMailButton',
        businessEvent: 'Investaja_Invitation_MailButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationFaceButton: {
        tag: 'InvitationFaceButton',
        businessEvent: 'Investaja_Invitation_FaceButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationShareCancelButton: {
        tag: 'InvitationShareCancelButton',
        businessEvent: 'Investaja_Invitation_ShareCancelButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationRouteDetailButton: {
        tag: 'InvitationRouteDetailButton',
        businessEvent: 'Investaja_Invitation_RouteDetailButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationCopyCodeButton: {
        tag: 'InvitationCopyCodeButton',
        businessEvent: 'Investaja_Invitation_CopyCodeButton',
        elementType: 'Button',
        eventType: 'C'
    },
    InvitationDetailPageEnter: {
        tag: 'InvitationDetailPageEnter',
        businessEvent: 'Investaja_InvitationDetailPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    InvitationDetailShareButton: mobileNumber => ({
        tag: 'InvitationDetailShareButton',
        businessEvent: 'Investaja_InvitationDetail_ShareButton',
        elementType: 'Button',
        eventType: 'C',
        elementContent: mobileNumber
    }),
    ActivityRegisterPageEnter: {
        tag: 'ActivityRegisterPageEnter',
        businessEvent: 'Investaja_ActivityRegisterPage_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AgreementPageEnter: {
        tag: 'AgreementPageEnter',
        businessEvent: 'Agreement_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    StaticAgreementPageEnter: {
        tag: 'StaticAgreementPageEnter',
        businessEvent: 'Static_Agreement_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },

    FourYearPageEnter: index => ({
        tag: 'FourYearPageEnter',
        businessEvent: 'Four_Year_Page_Enter',
        elementType: 'Button',
        eventType: 'P',
        elementContent: index
    }),
    FourYearShareButton: {
        tag: 'FourYearShareButton',
        businessEvent: 'Investaja_Four_Year_ShareButton',
        elementType: 'Button',
        eventType: 'C'
    },
    FourYearRegisterPageEnter: {
        tag: 'FourYearRegisterPageEnter',
        businessEvent: 'Four_Year_Register_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    FourYearRegisterButton: {
        tag: 'FourYearRegisterButton',
        businessEvent: 'Investaja_Four_Year_RegisterButton',
        elementType: 'Button',
        eventType: 'C'
    },
    FiveYearGetCouponPageEnter: {
        tag: 'FiveYearGetCouponPageEnter',
        businessEvent: 'Five_Year_Get_Coupon_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    ActivityReportPageEnter: {
        tag: 'ActivityReportPageEnter',
        businessEvent: 'Activity_Report_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    ProtocolPageEnter: {
        tag: 'ProtocolPageEnter',
        businessEvent: 'Protocol_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    RdlAccountBalancePageEnter: {
        tag: 'RdlAccountBalancePageEnter',
        businessEvent: 'Rdl_Account_Balance_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    RdlAccountWithdrawalPageEnter: {
        tag: 'RdlAccountWithdrawalPageEnter',
        businessEvent: 'Rdl_Account_Withdrawal_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    RdlAccountTopUpPageEnter: {
        tag: 'RdlAccountTopUpPageEnter',
        businessEvent: 'Rdl_Account_TopUp_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    RdlAccountLendTopUpPageEnter: {
        tag: 'RdlAccountLendTopUpPageEnter',
        businessEvent: 'Rdl_Account_LendTopUp_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    RdlAccountBindCardPageEnter: {
        tag: 'RdlAccountBindCardPageEnter',
        businessEvent: 'Rdl_Account_BindCard_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    },
    AssociateNpwpPageEnter: {
        tag: 'AssociateNpwpPageEnter',
        businessEvent: 'Associate_Npwp_Page_Enter',
        elementType: 'Button',
        eventType: 'P'
    }
};
