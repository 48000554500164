/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-22 16:45:37
 */

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';

import {rejectFailedStatus} from '@yqg/http';
import 'ssr-common/util/report';

import {initSensors} from '@shared/client/util/sensors';
import {initSentry} from '@shared/client/util/sentry';
import {getUserId} from '@shared/client/util/user-token';

import {isEasyCashLenderNative, minVersion} from 'ssr-common/util/webview/ua';
import {createClientEntry} from 'ssr-common/vue/ssr/entry-client';
import http from 'ssr-common/vue/vue-http';

import {rootTitle, noStageTitle} from './common/constant/config';
import {createApp} from './main';

http.interceptors.response.use(rejectFailedStatus());

const {router} = createClientEntry({createApp, rootTitle, noStageTitle});

// sensors start
const isInApp = isEasyCashLenderNative(navigator.userAgent);
if (minVersion(35913) || !isInApp) {
    const platformName = `easycash_${isInApp ? 'app' : 'outside'}`;
    Vue.prototype.$sensors = initSensors({
        platformName,
        country: 'indo',
        businessName: 'IDN_FIN',
        userId: getUserId(),
        useAppJsBridge: isInApp
    });
}
// sensors end

initSentry({
    router,
    dsn: 'https://73b4ba56b6b37a15ded0c664a3f62d14@sentry.fintopia.tech/72'
});

if (module.hot) {
    module.hot.accept();
}
