/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-10-10 15:59:18
 */

/* global __GTAG_ID__ */
import VueGtag from 'vue-gtag';
import {sync} from 'vuex-router-sync';

import {isServer} from 'ssr-common/constant/config';
import Vue from 'ssr-common/vue';
import http from 'ssr-common/vue/vue-http';

import {BRAND} from 'investaja/common/constant/config';

import App from './app';
import {createI18n} from './common/i18n';
import {createRouter} from './common/router';
import {createStore} from './common/store';
import EventUtil from './common/util/event';

export const createApp = context => {
    const i18n = createI18n(context);
    const router = createRouter(context);
    const store = createStore(context);
    sync(store, router);
    http.defaults.headers.common.build = context.build || '30302';

    router.afterEach(to => {
        const {query, meta = {}, name} = to;
        const {business = {}} = meta;
        const {enterEvent, uploadQuery, buriedPoint} = business;
        if (enterEvent && !isServer() && !buriedPoint) {
            const event = {
                ...enterEvent,
                elementContent: uploadQuery ? JSON.stringify(query) : ''
            };
            EventUtil.uploadEvent(event, to, BRAND);
        }

        if (buriedPoint && !isServer()) {
            const event = {
                tag: 'PageEnter',
                businessEvent: `Investaja_${name}_PageEnter`,
                elementType: 'Button',
                eventType: 'P',
                elementContent: uploadQuery ? JSON.stringify(query) : ''
            };
            EventUtil.uploadEvent(event, to, BRAND);
        }
    });

    Vue.use(VueGtag, {
        config: {id: __GTAG_ID__}
    }, router);

    const app = new Vue({
        i18n,
        router,
        store,
        render: h => h(App) // eslint-disable-line id-length
    });

    return {app, i18n, router, store};
};
