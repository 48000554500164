/**
 * @Author: Kyles Light
 * @Date: 2018-09-03 16:24:19.818
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:42
 */

import Router from 'vue-router';

import Vue from 'ssr-common/vue';

import routes from './routes';

Vue.use(Router);

export const createRouter = () => new Router({
    mode: 'history',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    }
});
