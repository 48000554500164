/**
 * @Author: lijiahang
 * @Date: 2024-03-06 16:24:19
 * @Last Modified by: lijiahang
 * @Last Modified time: 2024-03-06 16:24:19
 */
import { isServer } from 'ssr-common/constant/config';
const genKey = () => {
    let token = '';
    const codeAlphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    for (let i = 0; i < 8; i += 1) {
        token += codeAlphabet[Math.floor(Math.random() * 26)];
    }
    return `${token}-${Date.now()}`;
};
export const getKey = () => {
    if (isServer())
        return '';
    let userKey = window.localStorage.getItem('userKey');
    if (!userKey) {
        userKey = genKey();
        window.localStorage.setItem('userKey', userKey);
    }
    return userKey;
};
export default genKey;
