/*
 * @Author: zhaoyang
 * @Date: 2023-12-21 19:46:55
 * @Last Modified by: zhaoyang
 * @Last Modified time: 2023-12-21 19:49:56
 */
import UAParser from 'ua-parser-js';
export const getUa = () => {
    var _a, _b;
    return (_b = (_a = window === null || window === void 0 ? void 0 : window.navigator) === null || _a === void 0 ? void 0 : _a.userAgent) !== null && _b !== void 0 ? _b : '';
};
export const parserUa = ua => {
    const parser = new UAParser(ua);
    return parser.getResult();
};
export const getBrowserInfo = ua => {
    const result = parserUa(ua);
    return {
        browserInfo: result
    };
};
