/*
 * @Author: xiaodongyu
 * @Date 2019-04-09 18:31:22
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2021-09-10 11:45:25
 */

import VueI18n from 'vue-i18n';

import Vue from 'ssr-common/vue';

import EN_US from './languages/en-us.json';
import ID from './languages/id.json';

Vue.use(VueI18n);

export const createI18n = context => new VueI18n({
    locale: context.acceptLanguage,
    fallbackLocale: 'id',
    messages: {
        en: EN_US,
        zh: ID,
        id: ID
    },
    silentTranslationWarn: true
});
