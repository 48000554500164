/*
 * @Author: zhaoyang
 * @Date: 2024-04-16 14:51:33
 * @Last Modified by: danteclericuzio
 * @Last Modified time: 2024-05-21 17:10:40
 */

const routes = [
    {
        path: 'redemptioninfo/:orderId',
        name: 'redemptionInfo',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('investaja/app/webview/biz-new/redemption-info')
    },
    {
        path: 'loanrestruture',
        name: 'loanRestruture',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('investaja/app/webview/biz-new/loan-restructure')
    },
    {
        path: 'loanrestruture/sign-now',
        name: 'loanRestrutureSignNow',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('investaja/app/webview/biz-new/loan-restructure/sign-now')
    },
    {
        path: 'loanrestruture/agreement',
        name: 'loanRestrutureAgreement',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('investaja/app/webview/biz-new/loan-restructure/agreement')
    },
    {
        path: 'loanrestruture/contract',
        name: 'loanRestrutureContract',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('investaja/app/webview/biz-new/loan-restructure/contract')
    },
    {
        path: 'loanrestruture/contractsuccess',
        name: 'loanRestrutureContractSuccess',
        meta: {
            business: {
                buriedPoint: true,
                uploadQuery: true
            }
        },
        component: () => import('investaja/app/webview/biz-new/loan-restructure/contract-success')
    }
];

export default routes;
