/**
 * @Author: quxiaoya
 * @Date: 2019-05-10 13:38:44
 * @Last Modified by: ruiwang
 * @Last Modified time: 2023-04-07 16:03:42
 */

import Resource from '@yqg/resource';

import {getEurekaHost} from '../constant/config';

const api = {
    getHost: getEurekaHost,

    getLotteryInfo: {
        url: '/api/lottery/getLotteryInfo',
        method: 'post'
    },

    drawLottery: {
        url: '/api/lottery/drawLottery',
        method: 'post'
    },

    getLatestWinningResult: {
        url: '/api/lottery/getLatestWinningResult/:promotionId',
        method: 'get'
    },

    getLotteryConfig: {
        url: '/api/financing/lottery/config/:activityId',
        method: 'get'
    },

    sudokuDrawLottery: {
        url: '/api/financing/lottery/drawLottery/:activityId/:times',
        method: 'get'
    },

    chance: {
        url: '/api/financing/lotteryActivity/chance/:activityId',
        method: 'get'
    },

    chances: {
        url: '/api/financing/lottery/chances/:activityId',
        method: 'get'
    },

    results: {
        url: '/api/financing/lottery/results/:activityId',
        method: 'get'
    },

    resultDetails: {
        url: '/api/financing/lottery/resultDetails/:lotteryLogId',
        method: 'get'
    },

    carouselRecentlyReults: {
        url: '/api/financing/lottery/carouselRecentlyResults/:activityId',
        method: 'get'
    }
};

export default Resource.create(api);
