/**
 * @Author: lijiahang
 * @Date: 2024-03-06 16:24:19
 * @Last Modified by: fuzhongfeng
 * @Last Modified time: 2024-07-17 09:58:51
 */
import EcImage from 'ssr-common/vue/component/ec-image';

import EcLayout from './ec-layout/index.vue';
import NavBar from './ec-nav-bar.vue';

export default {

    install(Vue) {
        Vue.component('ec-layout', EcLayout);
        Vue.component('nav-bar', NavBar);
        Vue.component('ec-overlay', () => import('./ec-overlay.vue'));
        Vue.component('ec-expand-box', () => import('./ec-expand-box'));
        Vue.component('ec-modal', () => import('./ec-modal'));
        Vue.component('ec-image', EcImage);
    }
};
