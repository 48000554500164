/*
 * @Author: xiaodongyu
 * @Date 2019-04-09 15:10:59
 * @Last Modified by: chengyuzhang
 * @Last Modified time: 2021-03-02 16:32:42
 */

import AntdIcon from '@ant-design/icons-vue';
import ArrowLeftOutline from '@ant-design/icons/lib/outline/ArrowLeftOutline';
import DownOutline from '@ant-design/icons/lib/outline/DownOutline';
import RightOutline from '@ant-design/icons/lib/outline/RightOutline';
import shareAltOutline from '@ant-design/icons/lib/outline/ShareAltOutline';
import UpOutline from '@ant-design/icons/lib/outline/UpOutline';

import AboutUsOutline from './custom/outline/about-us';
import ArrowRightOutline from './custom/outline/arrow-right';
import IconArrowOutline from './custom/outline/icon-arrow';
import ServiceOutline from './custom/outline/service';
import TelephoneOutline from './custom/outline/telephone';

AntdIcon.add(
    RightOutline,
    ArrowRightOutline,
    AboutUsOutline,
    DownOutline,
    TelephoneOutline,
    ServiceOutline,
    IconArrowOutline,
    UpOutline,
    shareAltOutline,
    ArrowLeftOutline
);

export default AntdIcon;

